<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <div class="nav-search">
        <b-form-input
          class="pr-4"
          @input="onSearchHandler"
        />
        <div class="position-absolute nav-search-btn">
          <feather-icon
            icon="SearchIcon"
            size="24"
          />
        </div>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.user.name }}
            </p>
            <span class="user-status">{{ $store.state.user.role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Выход</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BFormInput,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BFormInput,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      this.$store.commit('user/CLEAR')
      this.$router.push({ name: 'login' })
    },
    onSearchHandler(value) {
      console.log(value)
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.$store.commit('app/UPDATE_GLOBAL_SEARCH', value)
      }, 600)
    },
  },
}
</script>
<style>
.nav-search{
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  position: relative;
}
.nav-search-btn{
  position: absolute;
  right: 52px;
  top: 7px;
  cursor: pointer;
  padding: 0 4px;
}
@media (max-width: 520px) {
  .nav-search{
    padding-left: 8px;
    padding-right: 08px;
  }
  .nav-search-btn{
    right: 12px;
  }
}
</style>
